import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    heading: `'Noto Sans TC', sans-serif`,
    body: ` 'Noto Sans TC', sans-serif`,
  },
  styles: {
    global: {
      body: {
        color: 'white',
        bg: 'black',
      },
      h1: {
        color: 'white',
      },
      h2: {
        color: 'white',
      },
      h3: {
        color: 'white',
      },
    },
  },
});

export default theme;
