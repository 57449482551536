/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState, useEffect } from 'react';
import { HStack, Text, VStack, Box } from '@chakra-ui/react';

const Countdown = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const targetDate = new Date('2024-08-21T23:59:59'); // 确保是当天的开始时间
    const updateCountdown = () => {
      const now = new Date();
      const difference = targetDate - now;

      if (difference <= 0) {
        setTimeLeft({
          days: 0,
          hours: 0,
          minutes: 0,
          seconds: 0,
        });
        return;
      }

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((difference / 1000 / 60) % 60);
      const seconds = Math.floor((difference / 1000) % 60);

      setTimeLeft({ days, hours, minutes, seconds });
    };
    updateCountdown();
    const timerId = setInterval(updateCountdown, 1000);

    return () => clearInterval(timerId);
  }, []);
  const formatTime = (time) => {
    return time.toString().padStart(2, '0');
  };
  return (
    <VStack>
      <Text
        color="#d55eb3"
        fontSize={{ base: 'sm', md: 'xl' }}
        fontWeight={'bold'}
        letterSpacing="0.2em"
        mb="2"
      >
        // 限時排行榜進行中，距離結束還有 {timeLeft.days}天 //
      </Text>
      {timeLeft.message ? (
        <Text fontSize="3xl" color="white">
          {timeLeft.message}
        </Text>
      ) : (
        <HStack spacing={4} mb="10">
          <TimeBox number={formatTime(timeLeft.hours)} unit="HOURS" />
          <TimeBox number={formatTime(timeLeft.minutes)} unit="MINUTES" />
          <TimeBox number={formatTime(timeLeft.seconds)} unit="SECONDS" />
        </HStack>
      )}
    </VStack>
  );
};

const TimeBox = (props) => {
  return (
    <Box
      bg="rgba(55, 0, 90, 0.8)"
      borderRadius={'15%'}
      px={3}
      py={3}
      display="flex"
      flexDirection="column"
      alignItems="center"
      color="#d55eb3"
      fontFamily={`'Outfit', sans-serif`}
      margin={2}
    >
      <Text
        fontSize="6xl"
        justifyContent={'center'}
        fontWeight={'bold'}
        letterSpacing={'0.2rem'}
      >
        {props.number}
      </Text>
      <Text>{props.unit}</Text>
    </Box>
  );
};

export default Countdown;
