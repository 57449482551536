import Countdown from './Countdown';
import Ladder from './Ladder';
import {
  Box,
  VStack,
  Text,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  OrderedList,
  ListItem,
  Image,
} from '@chakra-ui/react';
import { useDisclosure } from '@chakra-ui/react';
function MainPage() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <div>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="black">活動詳情</ModalHeader>
          <ModalCloseButton />
          <ModalBody color="black">
            <OrderedList mb="4">
              <ListItem>
                於2024.08.09~2024.08.21，贈送最新禮物【星火】給你最心儀的創作者。
              </ListItem>
              <ListItem>贈送星火的數量將會記錄至官方排行榜。</ListItem>
              <ListItem>
                排行榜達前10名，將獲得創作者獨家私密實體贈禮。
              </ListItem>
            </OrderedList>
            <Text>
              贈送星火皆有機會獲得創作者私密小禮物！私訊喜歡的創作者即可暸解詳情！
            </Text>
            <Text>
              *《NightCo》保有以上活動及獎項內容修改之權利，並有權決定修改、取消、暫停或終止活動及贈送內容，最新詳情請見官方通知。』
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              關閉
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Box
        backgroundImage={
          'url(https://nightco-asset.b-cdn.net/campaign/2024-77-star-fire/scenic-view-night-sky-with-stars.jpg)'
        }
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundBlendMode="overlay"
        position={'relative'}
        _before={{
          display: 'block',
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          opacity: 0.8,
          zIndex: 0,
          bg: 'linear-gradient(rgb(48,21,100), rgb(71,14,100), rgb(34,11,87))',
        }}
        // backgroundAttachment="fit"
        // height="100vh"
      >
        <Box position={'relative'}>
          <VStack>
            <Button
              position="fixed"
              top="75px"
              right="0px"
              px="8"
              bg="purple.500"
              color="white"
              backgroundImage="linear-gradient(to bottom left, rgb(220,147,234) 0%, rgb(141,45,137) 100%)"
              borderTopLeftRadius="full"
              borderBottomLeftRadius="full"
              size="lg"
              borderColor="rgb(231,140,233)"
              borderWidth="2px"
              fontSize={20}
              letterSpacing={'0.2rem'}
              onClick={onOpen}
              zIndex={3}
            >
              活動詳情
            </Button>
            <Box height={'20vh'} />

            <Image
              src="https://nightco-asset.b-cdn.net/campaign/2024-77-star-fire/vega.png"
              width={{ base: '200px', md: '300px' }}
              opacity={0.9}
              mb={-5}
            />
            <Text
              fontSize={{ base: '5xl', md: '7xl' }}
              fontWeight="bold"
              color="white"
              textShadow="0 0 5px #FFF, 0 0 20px #FF00FF"
              letterSpacing={'0.05em'}
              mb="10"
            >
              星火募集排行榜
            </Text>

            <Countdown />
            <Ladder />
            <Box backgroundColor="black" py="20" width="100%" px={4}>
              <VStack>
                <Text
                  fontWeight={'bold'}
                  fontSize={{ base: 'xl', md: '2xl' }}
                  mb="10"
                >
                  注意事項
                </Text>
                <Text
                  color="white"
                  letterSpacing={'0.2rem'}
                  lineHeight={'tall'}
                  fontSize={{ base: 'sm', md: 'md' }}
                >
                  •《NightCo》保有以上活動及獎項內容修改之權利，並有權決定修改、取消、暫停或終
                  止活動及贈送內容，最新詳情請見官方通知。
                  <br />
                  •排行於08/21
                  23:59結算，以最終電磁紀錄為主要依據，達成目標即可獲得獎勵資格。
                  <br />
                  •獲得獎勵資格的使用者，將由官方於8/31前，以Email方式通知獲獎並給予表單填寫得
                  {/* <br /> */}
                  獎者資訊，請留意註冊帳號之mail是否正確，避免權益受損。
                </Text>
              </VStack>
            </Box>
          </VStack>
        </Box>
      </Box>
    </div>
  );
}
export default MainPage;
