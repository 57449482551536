/* eslint-disable react/jsx-no-comment-textnodes */
import MainPage from './MainPage';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<MainPage />} />,
      {/* <Route path="/detail" element={<DetailPage />} /> */}
    </>
  )
);

const App = () => {
  return <RouterProvider router={router} />;
};
export default App;
