import React, { useEffect, useState } from 'react';
import { Box, HStack, Text, Spinner, Avatar } from '@chakra-ui/react';
// import { faker } from '@faker-js/faker';

const Ladder = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [users, setUsers] = useState([]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     const users = Array.from({ length: 20 }, () => ({
  //       displayName: faker.person.fullName(),
  //       picture: faker.image.avatar(),
  //       total: faker.number.int({ min: 1000, max: 1000000 }),
  //     }));
  //     await new Promise((resolve) => setTimeout(resolve, 3000));
  //     setUsers(users);
  //     setIsLoaded(true);
  //   };
  //   fetchData();
  // }, []);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(process.env.REACT_APP_NIGHTCO_API_URL);
      const json = await response.json();
      setUsers(json['users']);
      setIsLoaded(true);
    };
    fetchData();
  }, []);

  // first 10 users no sort
  const firstHalfUsers = users.slice(0, 10);
  const secondHalfUsers = users.slice(10, 20);
  return isLoaded ? (
    <Box mb={20}>
      <Box
        borderRadius={'3xl'}
        borderColor={'purple'}
        borderWidth={'2px'}
        bg="rgba(75, 0, 130, 0.2)"
        px={{ base: 5, md: 12 }}
        pt={5}
        pb={5}
        position="relative"
        zIndex={2}
      >
        {firstHalfUsers.map((user, index) => (
          <div key={index}>
            <LadderTile key={index} index={index} user={user} />
            {index !== firstHalfUsers.length - 1 ? <Divider /> : null}
          </div>
        ))}
      </Box>
      {secondHalfUsers.length !== 0 && (
        <Box
          borderRadius={'3xl'}
          bg="rgba(45, 19, 84, 0.8)"
          pt={'120px'}
          pb={6}
          px={{ base: 5, md: 12 }}
          mt={'-100px'}
          position="relative"
          zIndex={1}
        >
          {secondHalfUsers.map((user, index) => (
            <div key={index + 10}>
              <LadderTile key={index} index={index + 10} user={user} />
              {index !== secondHalfUsers.length - 1 ? <Divider /> : null}
            </div>
          ))}
        </Box>
      )}
    </Box>
  ) : (
    <Box
      borderRadius={'3xl'}
      borderColor={'purple'}
      borderWidth={'2px'}
      bg="rgba(75, 0, 130, 0.8)"
      p={12}
      m={10}
    >
      <Spinner size="xl" thickness="4px" speed="0.65s" color="purple.500" />
    </Box>
  );
};

const LadderTile = (props) => {
  return (
    <Box key={props.index} minWidth={{ base: '300px', md: '500px' }}>
      <HStack spacing={10} alignItems="center">
        <Text width="2rem" fontSize={'2xl'}>
          {props.index + 1}
        </Text>
        <Avatar src={props.user.picture} />
        <Text flex="1">{props.user.displayName}</Text>
        <Text justify="flex-end">{props.user.total} </Text>
      </HStack>
    </Box>
  );
};
const Divider = () => {
  return <Box bg="white" w="100%" h="0.5px" my={5} opacity={'0.5'} />;
};

export default Ladder;
